import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import LinkInternal from '../LinkInternal';
import Socials from '../Socials';
import ROUTES from '../../constants/routes';
import { onAirSocialsLinks } from '../../utils/social';
import LinkExternal from '../LinkExternal';
import OnairLogo from '../svgs/OnairLogo';
import { Logos } from './Logos';
import messages from './messages';
import { trackHelpdeskViewed } from '../../utils/analytics';
import { LinkType } from '@/types/Notification';
import { fetchMenu } from '@/queries/menuQueries';
export interface MenuItem {
  label: string;
  href: string;
  target?: string;
  ariaLabel?: string;
}
export interface MenuGroup {
  title: string;
  menuItems: MenuItem[];
}
export interface FooterProps {
  disableTopLine?: boolean;
}
const Footer: FC<FooterProps> = ({
  disableTopLine
}) => {
  const [menuGroups, setMenuGroups] = useState<any>([]); // Initialize as an empty array
  const [isLoading, setIsLoading] = useState(true); // State to track loading

  // Fetch the footer menu from Contentful
  useEffect(() => {
    const fetchMenus = async () => {
      try {
        const footerMenu = await fetchMenu('Footer');
        if (footerMenu && footerMenu.menuGroups) {
          setMenuGroups(footerMenu.menuGroups as MenuGroup[]); // Access menuGroups correctly
        }
      } catch (error) {
        console.error('Error fetching footer menu:', error);
      } finally {
        setIsLoading(false); // Set loading to false after fetching
      }
    };
    fetchMenus();
  }, []);
  return <footer className={classNames('footer py-4 py-md-7', {
    'footer--no-topline': disableTopLine
  })} data-sentry-component="Footer" data-sentry-source-file="Footer.tsx">
            <div className="container">
                <div className="d-flex flex-column">
                    <div className="d-flex flex-column footer__section-links flex-md-row justify-content-center align-items-center justify-content-md-start align-items-md-start order-2 order-md-1">
                        {/* Dynamically Render Menu Groups and Menu Items */}
                        {menuGroups.map((group: MenuGroup, groupIndex: number) => <div key={groupIndex} className="footer__menu-group footer__menu-links col-md-4 col-lg-4">
                                    <div className="footer__menu-heading">
                                        <p className="footer__title">
                                            {group.title}
                                        </p>
                                    </div>
                                    <ul className="footer-text">
                                        {group.menuItems.map((item: any, itemIndex: number) => <li key={itemIndex}>
                                                    {item.href.startsWith('http') ? <LinkExternal to={item.href} type={LinkType.LINK_STYLED_IN_COMPONENT} className="footer-link footer-text" shouldOpenInNewTab={item.target === '_blank'} shouldFollow={item.target !== '_blank'}>
                                                            {item.label}
                                                        </LinkExternal> : <LinkInternal to={item.href} shouldFollow={item.target !== '_blank'} type={LinkType.LINK_STYLED_IN_COMPONENT} className="footer-link footer-text">
                                                            {item.label}
                                                        </LinkInternal>}
                                                </li>)}
                                    </ul>
                                </div>)}
                    </div>

                    <div className="d-flex flex-column justify-content-center align-items-center text-align-center flex-md-row justify-content-md-start align-items-md-stretch col-md-12 order-1 order-md-2">
                        <div className="footer__menu-group col-md-6 col-lg-4 col-xl-4">
                            <div className="footer__logo d-flex justify-content-center justify-content-md-start">
                                <LinkInternal to="/" type={LinkType.LINK_STYLED_IN_COMPONENT} className="footer__logo__icon d-flex align-items-center p-0" ariaLabel="navigate to home page" data-sentry-element="LinkInternal" data-sentry-source-file="Footer.tsx">
                                    <OnairLogo data-sentry-element="OnairLogo" data-sentry-source-file="Footer.tsx" />
                                </LinkInternal>
                            </div>
                            <div className="footer__socials order-3 d-none d-md-flex">
                                <Socials links={onAirSocialsLinks} data-sentry-element="Socials" data-sentry-source-file="Footer.tsx" />
                            </div>
                            <div className="footer__socials d-flex d-md-none justify-content-center">
                                <Socials links={onAirSocialsLinks} data-sentry-element="Socials" data-sentry-source-file="Footer.tsx" />
                            </div>
                        </div>
                        <div className="d-none d-md-flex">
                            <Logos data-sentry-element="Logos" data-sentry-source-file="Footer.tsx" />
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-column flex-md-row d-flex d-md-none order-4">
                    <Logos centered data-sentry-element="Logos" data-sentry-source-file="Footer.tsx" />
                </div>

                <div className="footer__bottombar-copyright d-flex flex-grow-1 justify-content-center">
                    <p className="copyright-text">
                        © {new Date().getFullYear()} {messages.copyrights}{' '}
                        <br className="d-inline-block d-md-none" />
                        <LinkInternal to={ROUTES.termsAndConditions} type={LinkType.LINK_STYLED_IN_COMPONENT} data-sentry-element="LinkInternal" data-sentry-source-file="Footer.tsx">
                            {messages.terms_and_conditions}
                        </LinkInternal>
                        ,{' '}
                        <LinkInternal to={ROUTES.privacyPolicy} type={LinkType.LINK_STYLED_IN_COMPONENT} data-sentry-element="LinkInternal" data-sentry-source-file="Footer.tsx">
                            {messages.privacy_policy}
                        </LinkInternal>{' '}
                        &{' '}
                        <LinkInternal to={ROUTES.cookiePolicy} type={LinkType.LINK_STYLED_IN_COMPONENT} data-sentry-element="LinkInternal" data-sentry-source-file="Footer.tsx">
                            {messages.cookies}
                        </LinkInternal>
                        .
                    </p>
                </div>
            </div>
        </footer>;
};
export default Footer;