import * as React from 'react';
export default () => <svg width="120" height="40" viewBox="0 0 120 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M110.135 7.92969e-06H9.53468C9.16798 7.92969e-06 8.80568 7.92975e-06 8.43995 0.00200793C8.1338 0.00400793 7.83009 0.00981793 7.521 0.0147079C6.84951 0.0226097 6.17961 0.0816842 5.5171 0.191418C4.85552 0.303541 4.21467 0.514924 3.61622 0.818418C3.0185 1.12448 2.47235 1.52218 1.99757 1.99708C1.5203 2.47064 1.12246 3.01802 0.81935 3.61817C0.5154 4.21712 0.304641 4.85895 0.19435 5.52149C0.0830109 6.18319 0.0230984 6.85253 0.01515 7.52349C0.00587 7.83009 0.00489 8.13771 0 8.44435V31.5586C0.00489 31.8691 0.00587 32.1699 0.01515 32.4805C0.0231008 33.1514 0.0830134 33.8207 0.19435 34.4824C0.304336 35.1453 0.515108 35.7875 0.81935 36.3867C1.12233 36.9849 1.52022 37.5301 1.99757 38.001C2.47054 38.478 3.01705 38.876 3.61622 39.1797C4.21467 39.484 4.85545 39.6967 5.5171 39.8105C6.17972 39.9194 6.84956 39.9785 7.521 39.9873C7.83009 39.9941 8.1338 39.998 8.43995 39.998C8.80567 40 9.168 40 9.53468 40H110.135C110.494 40 110.859 40 111.219 39.998C111.523 39.998 111.836 39.9941 112.141 39.9873C112.811 39.9789 113.479 39.9198 114.141 39.8105C114.804 39.6959 115.448 39.4833 116.049 39.1797C116.647 38.8758 117.193 38.4779 117.666 38.001C118.142 37.5282 118.541 36.9835 118.848 36.3867C119.15 35.7871 119.358 35.145 119.467 34.4824C119.578 33.8206 119.64 33.1515 119.652 32.4805C119.656 32.1699 119.656 31.8691 119.656 31.5586C119.664 31.1953 119.664 30.834 119.664 30.4648V9.53614C119.664 9.16993 119.664 8.80665 119.656 8.44435C119.656 8.13771 119.656 7.83009 119.652 7.52345C119.64 6.85243 119.578 6.18324 119.467 5.52145C119.358 4.85929 119.149 4.21751 118.848 3.61813C118.23 2.4152 117.252 1.43603 116.049 0.818328C115.448 0.515575 114.804 0.304248 114.141 0.191328C113.48 0.0811107 112.811 0.0220157 112.141 0.0145679C111.836 0.00968793 111.523 0.00382793 111.219 0.00187793C110.859 -0.00012207 110.494 -0.00012207 110.135 -0.00012207V7.92969e-06Z" fill="#A6A6A6" />
        <path d="M8.44487 39.125C8.14019 39.125 7.84287 39.1211 7.54058 39.1143C6.91436 39.1061 6.2896 39.0516 5.67144 38.9512C5.09503 38.8519 4.53664 38.6673 4.0147 38.4033C3.49754 38.1415 3.02585 37.7983 2.6177 37.3867C2.20364 36.98 1.85891 36.5082 1.59719 35.9902C1.33258 35.4688 1.14945 34.9099 1.05419 34.333C0.951311 33.7131 0.895651 33.0863 0.887687 32.458C0.881347 32.2471 0.873047 31.5449 0.873047 31.5449V8.44434C0.873047 8.44434 0.881887 7.75293 0.887737 7.5498C0.895363 6.92248 0.950699 6.29665 1.05327 5.67773C1.14871 5.09925 1.33197 4.53875 1.59673 4.01563C1.85749 3.49794 2.2003 3.02586 2.61187 2.61768C3.02297 2.20562 3.49617 1.8606 4.01421 1.59521C4.53495 1.33209 5.09228 1.14873 5.66753 1.05127C6.28772 0.949836 6.91465 0.894996 7.54304 0.88721L8.44536 0.875H111.214L112.127 0.8877C112.75 0.895099 113.371 0.94945 113.985 1.05029C114.566 1.14898 115.13 1.33362 115.656 1.59814C116.694 2.13299 117.539 2.97916 118.071 4.01807C118.332 4.53758 118.512 5.09351 118.607 5.66699C118.71 6.29099 118.768 6.92174 118.78 7.5542C118.783 7.8374 118.783 8.1416 118.783 8.44434C118.791 8.81934 118.791 9.17627 118.791 9.53613V30.4648C118.791 30.8281 118.791 31.1826 118.783 31.54C118.783 31.8652 118.783 32.1631 118.779 32.4697C118.768 33.0909 118.711 33.7104 118.608 34.3232C118.515 34.9043 118.333 35.4675 118.068 35.9932C117.805 36.5056 117.462 36.9733 117.053 37.3789C116.644 37.7927 116.172 38.1379 115.653 38.4014C115.128 38.6674 114.566 38.8527 113.985 38.9512C113.367 39.0522 112.742 39.1067 112.116 39.1143C111.823 39.1211 111.517 39.125 111.219 39.125L110.135 39.127L8.44487 39.125Z" fill="black" />
        <path d="M24.769 20.3007C24.7797 19.466 25.0014 18.6477 25.4135 17.9217C25.8255 17.1957 26.4145 16.5858 27.1256 16.1486C26.6738 15.5035 26.0779 14.9725 25.385 14.598C24.6922 14.2234 23.9216 14.0156 23.1344 13.991C21.4552 13.8147 19.8272 14.9958 18.9715 14.9958C18.0992 14.9958 16.7817 14.0085 15.363 14.0376C14.4453 14.0673 13.551 14.3341 12.7671 14.8122C11.9832 15.2903 11.3365 15.9632 10.8901 16.7655C8.95607 20.1139 10.3987 25.035 12.2513 27.7416C13.1782 29.0669 14.2615 30.5474 15.6789 30.4949C17.066 30.4374 17.584 29.6104 19.2583 29.6104C20.9171 29.6104 21.4031 30.4949 22.8493 30.4615C24.3377 30.4374 25.2754 29.1303 26.1698 27.7924C26.8358 26.848 27.3483 25.8043 27.6882 24.6999C26.8235 24.3341 26.0856 23.722 25.5665 22.9397C25.0474 22.1574 24.77 21.2396 24.769 20.3007V20.3007Z" fill="white" />
        <path d="M22.0376 12.2109C22.8491 11.2367 23.2489 9.98451 23.1521 8.72028C21.9123 8.8505 20.767 9.44306 19.9445 10.3799C19.5424 10.8376 19.2344 11.37 19.0381 11.9468C18.8419 12.5235 18.7612 13.1333 18.8008 13.7413C19.4209 13.7477 20.0344 13.6132 20.5951 13.3482C21.1558 13.0831 21.649 12.6942 22.0376 12.2109Z" fill="white" />
        <path d="M42.3027 27.1396H37.5693L36.4326 30.4961H34.4277L38.9111 18.0781H40.9941L45.4775 30.4961H43.4385L42.3027 27.1396ZM38.0596 25.5908H41.8116L39.962 20.1435H39.9102L38.0596 25.5908Z" fill="white" />
        <path d="M55.1602 25.9697C55.1602 28.7832 53.6543 30.5908 51.3819 30.5908C50.8062 30.6209 50.2337 30.4883 49.7299 30.2082C49.2261 29.928 48.8114 29.5117 48.5332 29.0068H48.4902V33.4912H46.6318V21.4424H48.4307V22.9482H48.4649C48.7558 22.4458 49.1776 22.0316 49.6852 21.7497C50.1928 21.4679 50.7674 21.3289 51.3477 21.3476C53.6455 21.3477 55.1602 23.1641 55.1602 25.9697ZM53.25 25.9697C53.25 24.1367 52.3028 22.9316 50.8574 22.9316C49.4375 22.9316 48.4824 24.1621 48.4824 25.9697C48.4824 27.7939 49.4375 29.0156 50.8574 29.0156C52.3028 29.0156 53.25 27.8193 53.25 25.9697Z" fill="white" />
        <path d="M65.125 25.9697C65.125 28.7832 63.6192 30.5908 61.3467 30.5908C60.7711 30.6209 60.1986 30.4883 59.6948 30.2082C59.191 29.928 58.7762 29.5117 58.4981 29.0068H58.4551V33.4912H56.5967V21.4424H58.3955V22.9482H58.4297C58.7206 22.4458 59.1424 22.0316 59.65 21.7497C60.1576 21.4679 60.7322 21.3289 61.3125 21.3476C63.6104 21.3476 65.125 23.164 65.125 25.9697ZM63.2149 25.9697C63.2149 24.1367 62.2676 22.9316 60.8223 22.9316C59.4024 22.9316 58.4473 24.1621 58.4473 25.9697C58.4473 27.7939 59.4024 29.0156 60.8223 29.0156C62.2676 29.0156 63.2149 27.8193 63.2149 25.9697H63.2149Z" fill="white" />
        <path d="M71.711 27.0361C71.8487 28.2676 73.045 29.0761 74.6797 29.0761C76.2461 29.0761 77.3731 28.2675 77.3731 27.1572C77.3731 26.1933 76.6934 25.6162 75.084 25.2207L73.4746 24.833C71.1944 24.2822 70.1358 23.2158 70.1358 21.4853C70.1358 19.3427 72.003 17.871 74.6543 17.871C77.2783 17.871 79.0772 19.3427 79.1377 21.4853H77.2617C77.1494 20.246 76.125 19.498 74.6279 19.498C73.1309 19.498 72.1065 20.2548 72.1065 21.3564C72.1065 22.2343 72.7608 22.7509 74.3613 23.1464L75.7295 23.4823C78.2773 24.0849 79.3359 25.1083 79.3359 26.9247C79.3359 29.248 77.4854 30.703 74.542 30.703C71.7881 30.703 69.9287 29.2821 69.8086 27.036L71.711 27.0361Z" fill="white" />
        <path d="M83.3467 19.2998V21.4424H85.0684V22.9141H83.3467V27.9053C83.3467 28.6807 83.6914 29.042 84.4482 29.042C84.6526 29.0384 84.8567 29.0241 85.0596 28.999V30.4619C84.7193 30.5255 84.3734 30.5543 84.0273 30.5478C82.1943 30.5478 81.4795 29.8593 81.4795 28.1035V22.9141H80.1631V21.4424H81.4795V19.2998H83.3467Z" fill="white" />
        <path d="M86.0654 25.9697C86.0654 23.1211 87.7432 21.3311 90.3594 21.3311C92.9844 21.3311 94.6543 23.1211 94.6543 25.9697C94.6543 28.8262 92.9932 30.6084 90.3594 30.6084C87.7265 30.6084 86.0654 28.8262 86.0654 25.9697ZM92.7607 25.9697C92.7607 24.0156 91.8652 22.8623 90.3594 22.8623C88.8535 22.8623 87.959 24.0244 87.959 25.9697C87.959 27.9316 88.8535 29.0762 90.3594 29.0762C91.8652 29.0762 92.7607 27.9316 92.7607 25.9697H92.7607Z" fill="white" />
        <path d="M96.1865 21.4424H97.959V22.9834H98.002C98.1219 22.5021 98.4039 22.0768 98.8005 21.7789C99.197 21.481 99.6841 21.3287 100.18 21.3476C100.394 21.3469 100.607 21.3702 100.816 21.417V23.1553C100.546 23.0726 100.264 23.0347 99.9814 23.043C99.7115 23.032 99.4423 23.0796 99.1925 23.1825C98.9426 23.2854 98.718 23.4411 98.534 23.639C98.3501 23.8369 98.2111 24.0723 98.1267 24.3289C98.0422 24.5856 98.0143 24.8575 98.0449 25.126V30.4961H96.1865L96.1865 21.4424Z" fill="white" />
        <path d="M109.385 27.8369C109.135 29.4805 107.534 30.6084 105.486 30.6084C102.853 30.6084 101.218 28.8437 101.218 26.0127C101.218 23.1729 102.861 21.3311 105.408 21.3311C107.913 21.3311 109.488 23.0518 109.488 25.7969V26.4336H103.094V26.5459C103.064 26.8791 103.106 27.2148 103.216 27.5306C103.326 27.8464 103.502 28.1352 103.733 28.3778C103.963 28.6203 104.242 28.8111 104.552 28.9374C104.862 29.0637 105.195 29.1226 105.529 29.1103C105.968 29.1515 106.409 29.0498 106.786 28.8203C107.163 28.5909 107.455 28.246 107.62 27.8369L109.385 27.8369ZM103.103 25.1348H107.629C107.646 24.8352 107.6 24.5354 107.495 24.2541C107.391 23.9729 107.229 23.7164 107.021 23.5006C106.812 23.2849 106.561 23.1145 106.284 23.0003C106.006 22.8861 105.708 22.8305 105.408 22.8369C105.106 22.8351 104.806 22.8933 104.526 23.008C104.245 23.1227 103.991 23.2918 103.777 23.5054C103.562 23.7191 103.392 23.973 103.277 24.2527C103.161 24.5323 103.102 24.8321 103.103 25.1348V25.1348Z" fill="white" />
        <path d="M37.8262 8.73101C38.2158 8.70305 38.6067 8.76191 38.9709 8.90335C39.335 9.04478 39.6632 9.26526 39.9317 9.54889C40.2003 9.83251 40.4026 10.1722 40.524 10.5435C40.6454 10.9148 40.6829 11.3083 40.6338 11.6959C40.6338 13.6021 39.6035 14.6979 37.8262 14.6979H35.6709V8.73101H37.8262ZM36.5976 13.854H37.7226C38.0011 13.8707 38.2797 13.825 38.5382 13.7204C38.7967 13.6158 39.0287 13.4548 39.2172 13.2493C39.4057 13.0437 39.546 12.7987 39.6278 12.5321C39.7097 12.2655 39.7311 11.9839 39.6904 11.708C39.7281 11.4332 39.7046 11.1534 39.6215 10.8887C39.5383 10.6241 39.3977 10.3811 39.2096 10.1771C39.0216 9.97322 38.7907 9.81341 38.5337 9.70917C38.2766 9.60494 37.9996 9.55885 37.7226 9.57422H36.5976V13.854Z" fill="white" />
        <path d="M41.6809 12.4443C41.6526 12.1484 41.6865 11.8499 41.7803 11.5678C41.8742 11.2857 42.0259 11.0264 42.2259 10.8064C42.4258 10.5864 42.6695 10.4107 42.9414 10.2904C43.2132 10.1701 43.5072 10.108 43.8045 10.108C44.1017 10.108 44.3957 10.1701 44.6676 10.2904C44.9394 10.4107 45.1832 10.5864 45.3831 10.8064C45.583 11.0264 45.7348 11.2857 45.8286 11.5678C45.9225 11.8499 45.9563 12.1484 45.928 12.4443C45.9569 12.7406 45.9234 13.0396 45.8298 13.3221C45.7362 13.6046 45.5846 13.8644 45.3846 14.0848C45.1846 14.3052 44.9407 14.4814 44.6686 14.6019C44.3964 14.7225 44.1021 14.7847 43.8045 14.7847C43.5069 14.7847 43.2125 14.7225 42.9404 14.6019C42.6683 14.4814 42.4244 14.3052 42.2244 14.0848C42.0244 13.8644 41.8727 13.6046 41.7791 13.3221C41.6855 13.0396 41.6521 12.7406 41.6809 12.4443ZM45.0139 12.4443C45.0139 11.4683 44.5755 10.8975 43.8059 10.8975C43.0335 10.8975 42.5989 11.4683 42.5989 12.4444C42.5989 13.4282 43.0335 13.9946 43.8059 13.9946C44.5755 13.9946 45.014 13.4243 45.014 12.4443H45.0139Z" fill="white" />
        <path d="M51.5732 14.6978H50.6514L49.7207 11.3813H49.6504L48.7236 14.6978H47.8105L46.5693 10.1948H47.4707L48.2773 13.6308H48.3438L49.2695 10.1948H50.1221L51.0479 13.6308H51.1182L51.9209 10.1948H52.8096L51.5732 14.6978Z" fill="white" />
        <path d="M53.8535 10.1948H54.709V10.9102H54.7754C54.888 10.6532 55.0781 10.4379 55.3189 10.2941C55.5598 10.1503 55.8395 10.0852 56.1191 10.1079C56.3382 10.0914 56.5582 10.1245 56.7628 10.2046C56.9674 10.2847 57.1514 10.4098 57.3011 10.5706C57.4508 10.7315 57.5623 10.9239 57.6275 11.1337C57.6927 11.3436 57.7099 11.5654 57.6777 11.7827V14.6977H56.7891V12.0059C56.7891 11.2822 56.4746 10.9224 55.8174 10.9224C55.6686 10.9154 55.5201 10.9408 55.3821 10.9966C55.244 11.0524 55.1197 11.1375 55.0175 11.2458C54.9154 11.3542 54.8379 11.4834 54.7904 11.6245C54.7429 11.7657 54.7264 11.9154 54.7422 12.0635V14.6978H53.8535L53.8535 10.1948Z" fill="white" />
        <path d="M59.0938 8.43701H59.9824V14.6978H59.0938V8.43701Z" fill="white" />
        <path d="M61.218 12.4443C61.1897 12.1484 61.2236 11.8498 61.3175 11.5677C61.4113 11.2857 61.5631 11.0263 61.7631 10.8063C61.963 10.5863 62.2068 10.4106 62.4786 10.2903C62.7505 10.17 63.0445 10.1079 63.3418 10.1079C63.6391 10.1079 63.9331 10.17 64.205 10.2903C64.4768 10.4106 64.7206 10.5863 64.9205 10.8063C65.1205 11.0263 65.2723 11.2857 65.3661 11.5677C65.46 11.8498 65.4939 12.1484 65.4656 12.4443C65.4944 12.7406 65.4609 13.0396 65.3673 13.3221C65.2737 13.6046 65.122 13.8644 64.922 14.0848C64.722 14.3052 64.478 14.4814 64.2059 14.6019C63.9338 14.7224 63.6394 14.7847 63.3418 14.7847C63.0442 14.7847 62.7498 14.7224 62.4777 14.6019C62.2056 14.4814 61.9617 14.3052 61.7616 14.0848C61.5616 13.8644 61.4099 13.6046 61.3163 13.3221C61.2227 13.0396 61.1892 12.7406 61.218 12.4443ZM64.551 12.4443C64.551 11.4683 64.1125 10.8975 63.343 10.8975C62.5706 10.8975 62.136 11.4683 62.136 12.4444C62.136 13.4282 62.5706 13.9946 63.343 13.9946C64.1126 13.9946 64.551 13.4243 64.551 12.4443H64.551Z" fill="white" />
        <path d="M66.4004 13.4243C66.4004 12.6138 67.0039 12.1465 68.0752 12.0801L69.2949 12.0098V11.6211C69.2949 11.1455 68.9805 10.877 68.373 10.877C67.877 10.877 67.5332 11.0591 67.4346 11.3775H66.5742C66.665 10.604 67.3926 10.1079 68.4141 10.1079C69.543 10.1079 70.1797 10.6699 70.1797 11.6211V14.6978H69.3242V14.065H69.2539C69.1112 14.292 68.9108 14.477 68.6732 14.6012C68.4355 14.7254 68.1692 14.7844 67.9014 14.772C67.7123 14.7916 67.5213 14.7715 67.3405 14.7128C67.1598 14.6541 66.9933 14.5581 66.8519 14.4312C66.7105 14.3042 66.5972 14.149 66.5195 13.9756C66.4417 13.8022 66.4011 13.6144 66.4004 13.4243ZM69.2949 13.0396V12.6631L68.1953 12.7334C67.5752 12.7749 67.2939 12.9859 67.2939 13.3828C67.2939 13.7881 67.6455 14.0239 68.1289 14.0239C68.2706 14.0383 68.4137 14.024 68.5497 13.9819C68.6857 13.9398 68.8118 13.8708 68.9206 13.7789C69.0294 13.6871 69.1186 13.5743 69.1829 13.4473C69.2472 13.3203 69.2853 13.1816 69.2949 13.0396Z" fill="white" />
        <path d="M71.3477 12.4444C71.3477 11.0215 72.0791 10.1201 73.2168 10.1201C73.4982 10.1072 73.7775 10.1746 74.022 10.3145C74.2666 10.4544 74.4662 10.661 74.5977 10.9101H74.6641V8.43701H75.5527V14.6978H74.7012V13.9863H74.6309C74.4893 14.2338 74.2826 14.4378 74.0334 14.5763C73.7841 14.7148 73.5017 14.7825 73.2168 14.772C72.0713 14.772 71.3477 13.8706 71.3477 12.4444ZM72.2657 12.4444C72.2657 13.3994 72.7159 13.9741 73.4688 13.9741C74.2178 13.9741 74.6807 13.3911 74.6807 12.4483C74.6807 11.5098 74.2129 10.9185 73.4688 10.9185C72.7207 10.9185 72.2656 11.4971 72.2656 12.4444H72.2657Z" fill="white" />
        <path d="M79.2298 12.4443C79.2015 12.1484 79.2353 11.8499 79.3292 11.5678C79.423 11.2857 79.5748 11.0264 79.7747 10.8064C79.9746 10.5864 80.2183 10.4107 80.4902 10.2904C80.762 10.1701 81.056 10.108 81.3533 10.108C81.6506 10.108 81.9446 10.1701 82.2164 10.2904C82.4883 10.4107 82.732 10.5864 82.9319 10.8064C83.1319 11.0264 83.2836 11.2857 83.3775 11.5678C83.4713 11.8499 83.5052 12.1484 83.4768 12.4443C83.5057 12.7406 83.4722 13.0396 83.3786 13.3221C83.2851 13.6046 83.1334 13.8644 82.9334 14.0848C82.7334 14.3052 82.4895 14.4814 82.2174 14.6019C81.9453 14.7225 81.6509 14.7847 81.3533 14.7847C81.0557 14.7847 80.7613 14.7225 80.4892 14.6019C80.2171 14.4814 79.9732 14.3052 79.7732 14.0848C79.5732 13.8644 79.4216 13.6046 79.328 13.3221C79.2344 13.0396 79.2009 12.7406 79.2298 12.4443ZM82.5628 12.4443C82.5628 11.4683 82.1243 10.8975 81.3548 10.8975C80.5823 10.8975 80.1478 11.4683 80.1478 12.4444C80.1478 13.4282 80.5823 13.9946 81.3548 13.9946C82.1243 13.9946 82.5628 13.4243 82.5628 12.4443Z" fill="white" />
        <path d="M84.6689 10.1948H85.5244V10.9102H85.5908C85.7035 10.6532 85.8935 10.4379 86.1344 10.2941C86.3752 10.1503 86.655 10.0852 86.9346 10.1079C87.1537 10.0914 87.3737 10.1245 87.5783 10.2046C87.7829 10.2847 87.9668 10.4098 88.1165 10.5706C88.2662 10.7315 88.3778 10.9239 88.443 11.1337C88.5082 11.3436 88.5253 11.5654 88.4932 11.7827V14.6977H87.6045V12.0059C87.6045 11.2822 87.2901 10.9224 86.6328 10.9224C86.4841 10.9154 86.3356 10.9408 86.1975 10.9966C86.0594 11.0524 85.9351 11.1375 85.833 11.2458C85.7308 11.3542 85.6534 11.4834 85.6058 11.6245C85.5583 11.7657 85.5419 11.9154 85.5576 12.0635V14.6978H84.6689V10.1948Z" fill="white" />
        <path d="M93.5146 9.07373V10.2153H94.4902V10.9639H93.5146V13.2793C93.5146 13.751 93.709 13.9575 94.1514 13.9575C94.2646 13.9572 94.3778 13.9503 94.4902 13.937V14.6772C94.3307 14.7058 94.169 14.721 94.0068 14.7226C93.0186 14.7226 92.625 14.375 92.625 13.5068V10.9638H91.9102V10.2153H92.625V9.07373H93.5146Z" fill="white" />
        <path d="M95.7051 8.43701H96.5859V10.9185H96.6562C96.7744 10.6591 96.9696 10.4425 97.2153 10.2982C97.461 10.1539 97.7452 10.0888 98.0292 10.1118C98.2472 10.1 98.4651 10.1364 98.6674 10.2184C98.8697 10.3004 99.0513 10.4261 99.1995 10.5864C99.3476 10.7468 99.4585 10.9378 99.5243 11.146C99.5901 11.3541 99.6091 11.5742 99.58 11.7905V14.6978H98.6905V12.0098C98.6905 11.2905 98.3555 10.9263 97.7276 10.9263C97.5748 10.9137 97.4212 10.9347 97.2774 10.9878C97.1337 11.0408 97.0032 11.1247 96.8952 11.2334C96.7872 11.3421 96.7043 11.4732 96.6522 11.6173C96.6002 11.7614 96.5802 11.9152 96.5938 12.0679V14.6977H95.7051L95.7051 8.43701Z" fill="white" />
        <path d="M104.761 13.4819C104.641 13.8935 104.379 14.2495 104.022 14.4876C103.666 14.7258 103.237 14.8309 102.81 14.7847C102.514 14.7925 102.219 14.7357 101.946 14.6182C101.674 14.5006 101.43 14.3252 101.232 14.1041C101.034 13.8829 100.887 13.6214 100.8 13.3376C100.713 13.0537 100.69 12.7544 100.73 12.4605C100.691 12.1656 100.715 11.8656 100.801 11.581C100.888 11.2963 101.035 11.0335 101.231 10.8105C101.428 10.5874 101.671 10.4092 101.943 10.288C102.214 10.1668 102.509 10.1054 102.806 10.1079C104.059 10.1079 104.815 10.9639 104.815 12.3779V12.688H101.635V12.7378C101.621 12.9031 101.642 13.0694 101.696 13.2261C101.751 13.3829 101.837 13.5266 101.95 13.6481C102.063 13.7695 102.2 13.866 102.352 13.9314C102.504 13.9968 102.669 14.0297 102.835 14.0278C103.047 14.0533 103.263 14.0151 103.453 13.9178C103.644 13.8206 103.802 13.6689 103.906 13.4819L104.761 13.4819ZM101.635 12.0308H103.91C103.921 11.8796 103.9 11.7278 103.85 11.5851C103.799 11.4424 103.718 11.3119 103.614 11.2021C103.51 11.0922 103.383 11.0054 103.243 10.9472C103.103 10.8891 102.953 10.8608 102.801 10.8643C102.648 10.8623 102.495 10.8912 102.353 10.9491C102.21 11.0071 102.081 11.0929 101.972 11.2017C101.864 11.3104 101.778 11.4397 101.72 11.5821C101.662 11.7245 101.633 11.8771 101.635 12.0308H101.635Z" fill="white" />
    </svg>;