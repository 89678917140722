import React, { ChangeEvent, FC, useState } from 'react';
import classNames from 'classnames';
import LinkInternal from '../LinkInternal';
import InputError from './InputError';
import Button from '../Button';
import { ButtonType } from '@/enums/ButtonType';
export interface InputProps {
  placeholder?: string;
  label?: string;
  error?: string | boolean;
  type?: string;
  icon?: string;
  className?: string;
  link?: {
    to: string;
    text: string;
  };
  autoComplete?: string;

  // Functional props
  value?: string;
  defaultValue?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onEnter?: () => void;
  onBackspace?: () => void;

  // Boolean props
  optional?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  validated?: boolean;
  grow?: boolean;
  noPadding?: boolean;
  smallLabel?: boolean;
  alignError?: 'left' | 'center' | 'right';

  // Number props
  maxLength?: number;
  min?: number;
  max?: number;

  // Test props
  testId?: string;

  // TODO update all inputs to use the autoGeneratedProps, then it's easier
  // to set the name, onChange, validation, etc.
  // autoGeneratedProps?: UseFormRegisterReturn;
  autoGeneratedProps?: any;
}
export const Input: FC<InputProps> = ({
  placeholder,
  label,
  link,
  optional,
  disabled,
  readonly,
  error,
  validated,
  value,
  defaultValue,
  onChange,
  type = 'text',
  maxLength,
  grow,
  className,
  icon,
  onEnter,
  onBackspace,
  noPadding,
  smallLabel,
  testId,
  autoGeneratedProps,
  autoComplete,
  min,
  max,
  alignError
}) => {
  const [inputVisible, setInputVisible] = useState(false);
  const onChangeInternal = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(event);
  };
  const isPasswordField = () => type === 'password';
  const getType = () => {
    if (inputVisible) return 'text';
    return type;
  };
  const toggleVisibility = () => {
    setInputVisible(!inputVisible);
  };
  return <div className={classNames('onair-input__wrapper', {
    'onair-input--with-icon': icon,
    'onair-input--disabled': disabled || readonly,
    // reusing the disable style for 'readonly', just because then the form will serialize the value of the input
    'onair-input--error': error,
    'onair-input--validated': validated,
    'onair-input--grow': grow,
    'onair-input--password': type === 'password',
    'onair-input--no-padding': noPadding
  }, className)} data-sentry-component="Input" data-sentry-source-file="index.tsx">
            {/* Flex column reverse has been placed here to make sure that the input gets the tab focus before the label/link */}
            <div className="d-flex flex-column-reverse">
                <div className="onair-input__field-container">
                    {icon ? <i className={classNames('oa', icon, 'input-icon--left', disabled && 'oa-disabled')} /> : null}
                    <input type={getType()} disabled={disabled} readOnly={readonly} min={min} max={max} placeholder={placeholder || undefined} value={value} defaultValue={defaultValue} onChange={event => onChangeInternal(event)} maxLength={maxLength} onKeyDown={e => {
          if (e.key === 'Enter' && onEnter) onEnter();
          if ((e.key === 'Backspace' || e.key === 'Delete') && onBackspace) onBackspace();
        }} data-testid={testId} {...autoGeneratedProps || {}} autoComplete={autoComplete} />
                    {isPasswordField() ? <Button onClick={toggleVisibility} type={ButtonType.BUTTON_STYLED_IN_COMPONENT} className="onair onair-input__eye-button unstyled-button" icon={inputVisible ? 'oa-eye-closed' : 'oa-show'} /> : null}
                </div>
                {label || link ? <p className={classNames('onair-input__label-wrap body-text d-flex', {
        'caption-text': smallLabel
      })}>
                        <span className="onair-input__label">{label}</span>
                        {optional ? <span className="onair-input__optional caption-text">
                                Optional
                            </span> : null}
                        {link && <LinkInternal className="ms-auto" to={link.to}>
                                {link.text}
                            </LinkInternal>}
                    </p> : null}
            </div>
            {typeof error === 'string' && error && <InputError align={alignError} testId={testId ? `${testId}Error` : ''}>
                    {error}
                </InputError>}
        </div>;
};
export default Input;