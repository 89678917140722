import React from 'react';
import Icon from '../Icon';
import LinkInternal from '../LinkInternal';
import ROUTES from '../../constants/routes';
const NavbarSearch = () => <>
        <div className="col navbar__link navbar__link--icon d-md-block flex-grow-0">
            <LinkInternal to={ROUTES.search} data-sentry-element="LinkInternal" data-sentry-source-file="NavbarSearch.tsx">
                <Icon key="search-icon" icon="oa-search" data-sentry-element="Icon" data-sentry-source-file="NavbarSearch.tsx" />
            </LinkInternal>
        </div>
    </>;
export default NavbarSearch;