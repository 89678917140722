'use client';

import React, { FC, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import OnairLogo from '../svgs/OnairLogo';
import LinkInternal from '../LinkInternal';
import useIsClient from '../../state/useIsClient';
import ROUTES from '../../constants/routes';
import NavbarMobile from './NavbarMobile';
import NavbarLeft from './NavbarLeft';
import NavbarAccount from './NavbarAccount';
import NavbarNoAccount from './NavbarNoAccount';
import NavbarSearch from './NavbarSearch';
import NavbarCart from './NavbarCart';
import { useEventListener } from '@/utils/useEventListener.web';
export interface NavbarProps {
  forceShowsActive?: boolean;
  transparentBackground?: boolean;
  loggedIn: boolean;
  firstName?: string;
  lastName?: string;
  itemsInCart?: number;
  isBannerDisplayed?: boolean;
}
const Navbar: FC<NavbarProps> = ({
  forceShowsActive,
  transparentBackground,
  loggedIn,
  firstName,
  lastName,
  itemsInCart,
  isBannerDisplayed
}) => {
  const [defaultMobileMenuExpanded, setDefaultMobileMenuExpanded] = useState(false);
  const [myPlaceMobileMenuExpanded, setMyPlaceMobileMenuExpanded] = useState(false);
  const [scrolledToTop, setScrolledToTop] = useState(true);
  const isClient = useIsClient();

  // Decide if we need to change scrollToTop

  const onScroll = useCallback(() => {
    if (window.pageYOffset === 0) setScrolledToTop(true);else setScrolledToTop(false);
  }, []);
  useEventListener('scroll', onScroll);

  // This logic makes sure we don't get hydration errors
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    setIsLoggedIn(loggedIn);
  }, [loggedIn]);
  return <div className={classNames('navbar', {
    'navbar--transparent-bg': transparentBackground,
    'navbar--with-banner': isBannerDisplayed
  })} data-sentry-component="Navbar" data-sentry-source-file="Navbar.tsx">
            <div className={classNames('navbar__default', {
      'navbar__default--scrolled-to-top': scrolledToTop
    })}>
                <div className="container">
                    <div className="row">
                        <div className="navbar__links col-3 col-md-5">
                            <div className="row">
                                <NavbarLeft forceShowsActive={forceShowsActive} setDefaultMobileMenuExpanded={setDefaultMobileMenuExpanded} data-sentry-element="NavbarLeft" data-sentry-source-file="Navbar.tsx" />
                            </div>
                        </div>
                        <div className="navbar__logo col-6 col-md-2">
                            <LinkInternal to={ROUTES.home} ariaLabel="navigate to home page" data-sentry-element="LinkInternal" data-sentry-source-file="Navbar.tsx">
                                <OnairLogo data-sentry-element="OnairLogo" data-sentry-source-file="Navbar.tsx" />
                            </LinkInternal>
                        </div>
                        <div className="navbar__links navbar__links--right col-3 col-md-5">
                            <div className={classNames('row flex-nowrap justify-content-end', {
              'flex-grow-1': isLoggedIn
            })}>
                                <NavbarSearch data-sentry-element="NavbarSearch" data-sentry-source-file="Navbar.tsx" />
                                {loggedIn && isClient && firstName && lastName ? <NavbarAccount firstName={firstName} lastName={lastName} setMyPlaceMobileMenuExpanded={setMyPlaceMobileMenuExpanded} /> : <NavbarNoAccount />}
                                <NavbarCart itemsInCart={itemsInCart} data-sentry-element="NavbarCart" data-sentry-source-file="Navbar.tsx" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <NavbarMobile loggedIn={loggedIn} isClient={isClient} firstName={firstName} myPlaceMobileMenuExpanded={myPlaceMobileMenuExpanded} defaultMobileMenuExpanded={defaultMobileMenuExpanded} setDefaultMobileMenuExpanded={setDefaultMobileMenuExpanded} setMyPlaceMobileMenuExpanded={setMyPlaceMobileMenuExpanded} data-sentry-element="NavbarMobile" data-sentry-source-file="Navbar.tsx" />
        </div>;
};
export default Navbar;
export const dynamic = 'force-dynamic';