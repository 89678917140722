import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';
type InputErrorProps = {
  testId: string;
  align?: 'left' | 'center' | 'right';
  children: ReactNode | string;
};
const InputError: FC<InputErrorProps> = ({
  testId,
  align,
  children
}) => <p className={classNames('onair-input-error caption-text', {
  [`text-${align}`]: !!align
})} data-testid={testId} data-sentry-component="InputError" data-sentry-source-file="InputError.tsx">
        {children}
    </p>;
export default InputError;