import React from 'react';
import OnAirIcon from '../Icon';
type Props = {
  icon?: string;
  children: string | React.ReactNode;
};
export default function ErrorOption({
  icon,
  children
}: Props) {
  return <div className="d-flex flex-row align-items-center my-1" data-sentry-component="ErrorOption" data-sentry-source-file="ErrorOption.tsx">
            {icon && <OnAirIcon icon={icon} />}
            <span className="body-text ms-1">{children}</span>
        </div>;
}