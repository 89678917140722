import React, { FC } from 'react';
import Icon from '../Icon';
// import Dropdown from '../dropdown/Dropdown';
import messages from './messages';
export const Locales: FC = () => <div className="footer__menu-group flex-grow-1" data-sentry-component="Locales" data-sentry-source-file="Locales.tsx">
        <div className="footer__dropdown-container d-flex flex-row align-items-center">
            <div className="footer__dropdown-group d-flex flex-row align-items-center">
                <Icon icon="oa-language footer__dropdown-icon" data-sentry-element="Icon" data-sentry-source-file="Locales.tsx" />
                {/* <Dropdown
                    dark
                    selected={0}
                    options={[
                        messages.english,
                        messages.dutch,
                        messages.french,
                        messages.spanish,
                        messages.german,
                    ]}
                    className="footer__dropdown d-flex flex-row align-items-center justify-content-between"
                 /> */}
            </div>
            <div className="footer__dropdown-group d-flex flex-row align-items-center">
                <Icon icon="oa-globe footer__dropdown-icon" data-sentry-element="Icon" data-sentry-source-file="Locales.tsx" />
                {/* <Dropdown
                    dark
                    selected={0}
                    options={[messages.eu_amsterdam, messages.us_new_york]}
                    className="footer__dropdown d-flex flex-row align-items-center justify-content-between"
                 /> */}
            </div>
        </div>
    </div>;