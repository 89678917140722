import { normalizeContentfulResponse } from '@/utils/contentful/normalizers';
import { getEnvironmentVariableServerSide } from '../utils/server-functions/getEnvServerSide';
import { createClient } from 'contentful';

interface MenuItem {
  label: string;
  href: string;
  altText?: string;
  ariaLabel?: string;
  target?: string;
}

interface MenuGroup {
  title: string;
  menuItems: MenuItem[];
}

interface Menu {
  menuGroups: MenuGroup[];
}

// Fetch a specific type of menu (e.g., 'Footer', 'Main Menu') from Contentful
export async function fetchMenu(menuTitle: string): Promise<Menu> {
  const {
    CONTENTFUL_SPACE_ID,
    CONTENTFUL_ENVIRONMENT,
    CONTENTFUL_ACCESS_TOKEN,
  } = await getEnvironmentVariableServerSide([
    'CONTENTFUL_SPACE_ID',
    'CONTENTFUL_ENVIRONMENT',
    'CONTENTFUL_ACCESS_TOKEN',
  ]);

  if (!CONTENTFUL_SPACE_ID || !CONTENTFUL_ENVIRONMENT || !CONTENTFUL_ACCESS_TOKEN) {
    throw new Error('Missing required Contentful environment variables.');
  }

  try {
    const client = createClient({
      space: CONTENTFUL_SPACE_ID,
      environment: CONTENTFUL_ENVIRONMENT,
      accessToken: CONTENTFUL_ACCESS_TOKEN,
    });

    // Fetch entries based on the title of the menu
    const entries = await client.getEntries({
      content_type: 'menu',
      'fields.title': menuTitle, // Filter by menu title (e.g., 'Footer')
      include: 3,
    });

    const normalizedEntries: any = normalizeContentfulResponse(entries.items);

    if (normalizedEntries.length === 0 || !normalizedEntries[0].menuGroups) {
      throw new Error(`No menu groups found for the ${menuTitle} menu.`);
    }

    const menuGroups: Menu = {
      menuGroups: normalizedEntries[0].menuGroups.map((group: any) => ({
        title: group.title,
        menuItems: group.menuItems.map((item: any) => ({
          label: item.label,
          href: item.href,
          altText: item.altText,
          ariaLabel: item.ariaLabel,
          target: item.target || '_self',
        })),
      })),
    };

    return menuGroups;
  } catch (error: unknown) {
    if (error instanceof Error) {
      console.error(`Error fetching ${menuTitle} menu: ${error.message}`);
      throw new Error(`Failed to fetch the ${menuTitle} menu: ${error.message}`);
    }
    throw new Error('An unknown error occurred while fetching the menu.');
  }
}
