import React from 'react';
import messages from './messages';
import Icon from '../Icon';
export const NewsletterSuccess = () => <div className="flex-grow-1" data-sentry-component="NewsletterSuccess" data-sentry-source-file="NewsletterSucces.tsx">
        <div className="d-flex flex-grow-1 flex-row justify-content-center mb-1 justify-content-md-start">
            <Icon icon="oa-check newsletter-success-icon body-title" className="newsletter-success-icon body-title" data-sentry-element="Icon" data-sentry-source-file="NewsletterSucces.tsx" />
            <h3 className="newsletter-success-title body-title text-left mx-1">
                {messages.newsletter_success.title}
            </h3>
        </div>
        <div className="text-center">
            <p className="newsletter-success-text ">
                {messages.newsletter_success.body}
            </p>
        </div>
    </div>;